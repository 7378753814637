import React, {useEffect, useRef, useState, useMemo} from 'react';
import Image from 'next/future/image'
import Link from 'next/link';
import PropTypes from 'prop-types';

// import {PTtoReadable} from '../../utils/time'

import StrapiAPI from "../../data/strapi";

const VideoList = (props) => {
    const thumbRef = useRef(null);

    const isInViewport = useIsInViewport(thumbRef);

    const [channelThumb, setChannelThumb] = useState(false);
    const [channelName, setChannelName] = useState(false);


    const {video_id, title = '', index = 1, duration, type, slug, channel_id} = props
    const cleanTitle = title.replace(/[^\x00-\x7F]/g, '')
    const cleanDuration = false //PTtoReadable(duration)
    const cleanType = false //type === 'other' ? false : type

    useEffect(() => {
        if (isInViewport && !channelThumb) {
            StrapiAPI.getChannelThumbnail(channel_id).then(response => {
                const {data} = response
                const channel = data[0]
                if (channel?.attributes) setChannelThumb(channel.attributes.thumbnail)
                if (channel?.attributes) setChannelName(channel.attributes.name)

            })
        }
    }, [isInViewport])

    return (
        <div className={'mx-2'} ref={thumbRef}>
            <div className={'relative'}>
                <div className={'clip-youtube-parent'}>
                    <Link href={`/videos/${slug}/`} passHref>
                        <Image
                            src={`https://img.youtube.com/vi/${video_id}/hqdefault.jpg`}
                            loading='lazy'
                            alt={title}
                            className={'clip-youtube-thumb cursor-pointer'}
                            width={300}
                            height={180}
                        />
                    </Link>
                </div>
                <div className='flex flex-row h-11'>
                    <h5 className={'flex flex-row justify-start mt-1.5'}>
                        <span className={'text-sm line-clamp-2'}>{cleanTitle}</span>
                    </h5>
                </div>
                {channelThumb &&
                    <div className={`flex flex-row justify-start h-10 mt-2`}>
                        <img
                            className='rounded-full w-7 h-7 bg-slate-100'
                            src={channelThumb.toString()}
                            alt={''}
                            referrerPolicy="no-referrer"
                            onError={(e) => (e.currentTarget.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=')}
                        />
                        <h6 className={'ml-1.5 h-7 flex flex-col justify-center'}>
                            <span className={'text-xs line-clamp-1'}>{channelName}</span>
                        </h6>
                    </div>
                }

            </div>
        </div>
    );
}

function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () => {
            if (typeof window !== 'undefined') {
                return new IntersectionObserver(([entry]) =>
                        setIsIntersecting(entry.isIntersecting), {
                        threshold: 0
                    }
                )
            } else {
                return false
            }
        },
        [],
    )

    useEffect(() => {
        if (observer) {
            observer.observe(ref.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [ref, observer]);

    return isIntersecting;
}

VideoList.propTypes = {
    video_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    index: PropTypes.number
};

export default VideoList;

