import React from 'react'

import {useSelector} from 'react-redux'

import Footer from '../footer/footer'
import Navbar from '../navbar/navbar'
import Drawer from '../drawer/drawer'
import Search from '../../containers/search/search'

function Layout(props) {
    const {children, fullWidth = false} = props
    const layoutState = useSelector(state => state.layout)
    const {leftMenuOpen} = layoutState

    return (
        <div className='layout bg-white relative'>
            <div className='side-drawer'>
                <div className={`side-drawer-content flex flex-col ${leftMenuOpen ? 'left-open' : ''}`}>
                    <Navbar/>
                    <Search/>
                    <div className={`container pb-1 mx-auto grow w-full ${fullWidth ? '' : 'sm:w-200 md:w-250 lg:w-300 xl:w-325'}`}>
                        {children}
                    </div>
                    <Footer/>
                </div>
                <Drawer/>
            </div>
        </div>
    )
}

export default Layout
